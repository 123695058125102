document.getElementById('hide-flash') && document.getElementById('hide-flash').addEventListener('click', function () {
  document.getElementById('flash').remove();
});

const ready = function() {
  setTimeout(clearNotice, 3000);  //Flash fade
};
$(document).ready(ready);
$(document).on('page:load', ready);

function clearNotice(){
  $("#flash").animate({opacity:'0'}, 1500);
}

